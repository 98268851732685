<template>
  <basic-block :title="currentTerm.termName">
    <template #search>
      <el-row :gutter="24">
        <el-col :span="6">
          <el-select
            :size="$publicConfig.selectSize"
            v-model="search.campusCode"
            placeholder="请选择校区"
            @change="allSchool"
          >
            <el-option
              v-for="item in allschool"
              :key="item.campusCode"
              :label="item.campusName"
              :value="item.campusCode"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <el-select
            :size="$publicConfig.selectSize"
            v-model="search.gradeId"
            placeholder="请选择年级"
            @change="studentCheckRecordStatistics"
          >
            <el-option
              v-for="item in allGrade"
              :key="item.gradeCode"
              :label="item.grade"
              :value="item.gradeCode"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="12">
          <el-date-picker
            v-model="search.date"
            :size="$publicConfig.selectSize"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            :default-time="['00:00:00', '23:59:59']"
            value-format="timestamp"
            @change="studentCheckRecordStatistics"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-col>
        <el-col :span="6">
          <el-select
            :size="$publicConfig.selectSize"
            v-model="search.checkInType"
            placeholder="请选择"
            @change="studentCheckRecordStatistics"
          >
            <el-option
              v-for="item in list"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
    </template>
    <el-row :gutter="24">
      <el-col :span="10" style="height: 440px">
        <bar :barData="countState"></bar>
      </el-col>

      <el-col :span="14" style="height: 440px">
        <div id="grade_statistic" class="charts"></div>
      </el-col>

      <el-col :span="24">
        <el-table :data="tableData" style="width: 100%" stripe>
          <el-table-column prop="grade" label="年级"></el-table-column>
          <el-table-column prop="className" label="班级"></el-table-column>
          <el-table-column
            prop="studentAmount"
            label="学生人数"
          ></el-table-column>
          <el-table-column
            prop="totalCount"
            label="考勤总人次"
          ></el-table-column>
          <el-table-column prop="onTime" label="准时">
            <template scope="scope">
              <span>
                {{ scope.row.onTime }} ({{
                  scope.row.totalCount > 0
                    ? ((scope.row.onTime / scope.row.totalCount) * 100).toFixed(
                        2
                      ) + '%'
                    : '0.00%'
                }})
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="late" label="迟到">
            <template scope="scope">
              <span>
                {{ scope.row.late }} ({{
                  scope.row.totalCount > 0
                    ? ((scope.row.late / scope.row.totalCount) * 100).toFixed(
                        2
                      ) + '%'
                    : '0.00%'
                }})
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="leave" label="请假">
            <template scope="scope">
              <span>
                {{ scope.row.leave }} ({{
                  scope.row.totalCount > 0
                    ? ((scope.row.leave / scope.row.totalCount) * 100).toFixed(
                        2
                      ) + '%'
                    : '0.00%'
                }})
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="notArrive" label="缺勤">
            <template scope="scope">
              <span>
                {{ scope.row.notArrive }} ({{
                  scope.row.totalCount > 0
                    ? (
                        (scope.row.notArrive / scope.row.totalCount) *
                        100
                      ).toFixed(2) + '%'
                    : '0.00%'
                }})
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="操作">
            <template slot-scope="scope">
              <el-link type="primary" @click="goto(scope.row)"
                >查看详情</el-link
              >
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </basic-block>
</template>

<script>
let echarts = require('echarts')
import Bar from '@/views/attendance/analysis/components/bar'
import { mapGetters } from 'vuex'
import { getSchoolTerm } from '@/http/leaveSchool'
import { studentCheckRecordStatistics } from '@/http/analysis'

export default {
  data() {
    return {
      allStatisticEchart: null,
      gradeStatisticEchart: null,
      barData: {},
      search: {
        gradeId: '',
        date: [
          new Date(this.$cftY().split(' ')[0] + ' 00:00:00').getTime(),
          new Date(this.$cftY().split(' ')[0] + ' 23:59:59').getTime()
        ],
        checkInType: '',
        campusCode: ''
      },
      tableData: [],
      currentTerm: {},
      countState: {
        late: 0,
        leave: 0,
        notArrive: 0,
        onTime: 0,
        totalCount: 0,
        studentAmount: 0
      },
      gradeCount: {
        late: [],
        leave: [],
        notArrive: [],
        onTime: []
      },
      list: [
        {
          value: '',
          label: '全部时段'
        },
        {
          value: '1',
          label: '上午上学'
        },
        {
          value: '2',
          label: '上午放学'
        },
        {
          value: '3',
          label: '下午上学'
        },
        {
          value: '4',
          label: '下午放学'
        },
        {
          value: '5',
          label: '节次'
        }
      ]
      // allGrade : []
    }
  },
  components: {
    Bar
  },
  computed: {
    ...mapGetters(['userInfo', 'allNGrade', 'allNClass']),
    allGrade() {
      let allGrade = []
      this.allNGrade.forEach((item) => {
        item.gradeList.forEach((items) => {
          if (items.gradeCode == this.search.gradeId) {
            this.search.campusCode = item.campusCode
            allGrade = item.gradeList
          }
        })
      })
      return allGrade
    },
    allschool() {
      return [...this.allNGrade]
    }
  },
  created() {
    this.search.gradeId = this.$route.query.gradeId
    let params = {
      schoolId: this.userInfo.tnId,
      termId: 0
    }
    this.search.date = [this.$route.query.startDate, this.$route.query.endDate]
    getSchoolTerm(params).then((res) => {
      this.currentTerm = res.data
    })
    this.studentCheckRecordStatistics()
  },
  mounted() {
    this.gradeStatisticEchart = echarts.init(
      document.getElementById('grade_statistic')
    )
    this.setGradeStatisticEchart()
  },

  methods: {
    goto(row) {
      this.$router.push({
        path: '/attendance/classStatistic',
        query: row
      })
    },
    allSchool(e) {
      this.search.campusCode = e
      this.allschool.forEach((item) => {
        if (item.campusCode == e) {
          this.search.gradeId = item.gradeList[0].gradeCode
        }
      });
      this.studentCheckRecordStatistics()
    },
    studentCheckRecordStatistics() {
      let search = JSON.parse(JSON.stringify(this.search))
      search.schoolId = this.userInfo.tnId
      if (search.gradeId) {
        search.gradeIds = [search.gradeId]
      }

      studentCheckRecordStatistics(search).then((res) => {
        this.countState = {
          late: 0,
          leave: 0,
          notArrive: 0,
          onTime: 0,
          totalCount: 0,
          studentAmount: 0
        }
        this.gradeCount = {
          late: [],
          leave: [],
          notArrive: [],
          onTime: []
        }
        res.data.forEach((item) => {
          this.countState.late += item.late
          this.countState.leave += item.leave
          this.countState.notArrive += item.notArrive
          this.countState.onTime += item.onTime
          this.countState.totalCount += item.totalCount
          this.countState.studentAmount += item.studentAmount
        })
        this.allNClass.forEach((item) => {
          item.gradeList
            .filter((items) => items.gradeCode == this.search.gradeId)
            .forEach((items) => {
              let grades = res.data.filter((i) => i.grade == items.grade)
              if (grades.length > 0) {
                let late = 0,
                  leave = 0,
                  notArrive = 0,
                  onTime = 0
                grades.forEach((a) => {
                  late += a.late
                  leave += a.leave
                  notArrive += a.notArrive
                  onTime += a.onTime
                })
                this.gradeCount.late.push(late)
                this.gradeCount.leave.push(leave)
                this.gradeCount.notArrive.push(notArrive)
                this.gradeCount.onTime.push(onTime)
              } else {
                this.gradeCount.late.push(0)
                this.gradeCount.leave.push(0)
                this.gradeCount.notArrive.push(0)
                this.gradeCount.onTime.push(0)
              }
            })
        })
        this.tableData = res.data
        console.log(this.tableData)
        this.setGradeStatisticEchart()
      })
    },
    setGradeStatisticEchart() {
      console.log(this.gradeCount)
      let data = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        legend: {
          data: this.$publicConfig.attendanceType,
          bottom: 0
        },
        grid: {
          left: '0',
          right: '0',
          bottom: '40',
          top: '10',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            data: this.tableData
              .filter((item) => item.gradeId == this.search.gradeId)
              .map((item) => {
                return item.className
              })
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: this.$publicConfig.attendanceType[0],
            type: 'bar',
            stack: '广告',
            emphasis: {
              focus: 'series'
            },
            itemStyle: {
              color: '#00C9D9'
            },
            data: this.gradeCount.onTime
          },
          {
            name: this.$publicConfig.attendanceType[1],
            type: 'bar',
            stack: '广告',
            emphasis: {
              focus: 'series'
            },
            itemStyle: {
              color: this.$publicConfig.attendanceTypeColor[1]
            },
            barMaxWidth: 40,
            data: this.gradeCount.late
          },
          {
            name: this.$publicConfig.attendanceType[2],
            type: 'bar',
            stack: '广告',
            emphasis: {
              focus: 'series'
            },
            itemStyle: {
              color: this.$publicConfig.attendanceTypeColor[2]
            },
            data: this.gradeCount.leave
          },
          {
            name: this.$publicConfig.attendanceType[3],
            type: 'bar',
            stack: '广告',
            emphasis: {
              focus: 'series'
            },
            itemStyle: {
              color: this.$publicConfig.attendanceTypeColor[3]
            },
            data: this.gradeCount.notArrive
          }
        ]
      }
      this.gradeStatisticEchart.setOption(data)
    }
  }
}
</script>

<style lang="scss" scoped>
.charts {
  width: 100%;
  height: 391px;
}
</style>